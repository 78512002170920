import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-granallados',
  templateUrl: './granallados.component.html'
})
export class GranalladosComponent implements OnInit {

    vistaApartado:boolean = false;

    constructor(public _datosService:DatosService) {

  }

    ngOnInit() {
      this.vistaApartado = false;
      this._datosService.ponApartado('home');
      this.sacaApartado();
      this._datosService.updateTitle('Granallados Fercare');
      this._datosService.updateOgUrl('');
      //Updating Description tag dynamically with title
      this._datosService.updateDescription('fercare dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

    }

    sacaApartado() {
      setTimeout(() => {
        this.vistaApartado = true;
      }, 300);
    }
  }
