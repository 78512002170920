import { EventEmitter, Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from 'ng2-translate';
import { Meta, Title } from '@angular/platform-browser';



@Injectable({
  providedIn: 'root'
})
export class DatosService {
  public urlApi:string = 'https://www.fercare.com/api/'
  public urlNoti:string = 'https://www.fercare.com/'
  public selectedIdioma:string = '';
  public isCookies:boolean = false;
  public categoriaBlog:any = '';
  public apartado:string = '';

  public globalScroll: boolean = false;
  public menuOpen:boolean = false;

  espera: number = 3000;
  indexActual:number;
  interval;
  fotos:any = [];

  public vistaMenu:boolean = false;

  menu:any = ["home", "empresa", "granallados", "pintura", "calidad", "actualidad"];

  constructor(public http: HttpClient, private router:Router, public translate: TranslateService, private title: Title, private meta: Meta) {
    if(localStorage.getItem("isCookies") == "true"){
      this.isCookies = true;
    }
  }

  //PARA METAS

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  aceptaCookies() {
    this.isCookies = true;
    localStorage.setItem("isCookies", "true");
  }

  changeMenu() {
    this.menuOpen =! this.menuOpen;
  }



  cambiaVistaMenu() {
    this.vistaMenu = !this.vistaMenu;
  }

  getIdioma() {
   if(this.selectedIdioma == '') {
     this.selectedIdioma = this.translate.getBrowserLang();
   }

   return this.selectedIdioma;
 }

 getCampo(campo) {
  if(this.selectedIdioma == '') {
    this.selectedIdioma = this.translate.getBrowserLang();
  }
  let campoidioma = campo;
  if(this.selectedIdioma != 'es') {
    campoidioma = campo + "_" + this.selectedIdioma;
  }
  return campoidioma;
 }

 getNoticias( ) {
  let url:string = this.urlApi +`noticias/`;
  let headers = new HttpHeaders({
  })
  return this.http.get(url, { headers: headers })
   .map((resp:any) =>{
       return resp;
   });
  }

  getNoticia( laNoticia ) {
    let url:string = this.urlApi +`noticias/` + laNoticia;
    let headers = new HttpHeaders({
    })
    return this.http.get(url, { headers: headers })
     .map((resp:any) =>{
         return resp;
     });
  }

  getCategorias( ) {
   let url:string = this.urlApi +`noticias/categorias/`;
   let headers = new HttpHeaders({
   })
   return this.http.get(url, { headers: headers })
    .map((resp:any) =>{
        return resp;
    });
   }

   getTexto(clave ) {
   let url:string = this.urlApi + `preferencias/` + clave;
   let headers = new HttpHeaders({
   })
   return this.http.get(url, { headers: headers })
    .map((resp:any) =>{
        return resp;
    });
   }

   ponApartado(cual) {
     this.apartado = cual;
   }

//FUNCIONES DE SLIDE DE HOME
 getNext() {
   clearInterval(this.interval);
   this.interval = setInterval (() => {
       if(this.indexActual == this.fotos.length - 1) {
         this.indexActual = 0;
       }
       else {
         this.indexActual++;
       }
   }, this.espera);
 }

 limpiaFiltros() {
   this.categoriaBlog = '';
 }


}
