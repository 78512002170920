import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Meta } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  active: boolean = false;
  fotos:any = ['cabecera.jpg', 'cabecera2.jpg', 'cabecera3.jpg'];

  fotoActual: any = '';
  espera: number = 3000;
  indexActual:number;
  interval;

  vistaApartado:boolean = false;

  listadoNoticias:any = [];
  noticiasFiltradas:any = [];
  private noticias: any[];
  private noticia: any[];
  isNoticia: boolean = false;

  constructor(public _datosService:DatosService) {

  }

  ngOnInit() {
    this.indexActual = 0;
    this.vistaApartado = false;
    this._datosService.ponApartado('home');
    this.sacaApartado();
    this._datosService.updateTitle('Fercare Granallados y Pintura Industrial');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Fercare dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

    setTimeout(() => { this.active = true; this.getNext(); }, 500);

    this._datosService.getNoticias().subscribe(datos=>{
      this.listadoNoticias = datos;
      this.listadoNoticias = this.listadoNoticias.filter((noticia)=> noticia.publicado);
      console.log(this.listadoNoticias);

      //ORDENO POR POSITION
      this.listadoNoticias.sort((val1, val2)=> {return Number(moment(val2.publishing_date)) - Number(moment(val1.publishing_date))});
      this.noticia = this.listadoNoticias[0];
      console.log(this.noticia);
    })
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 300);
  }

  //FUNCIONES DE SLIDE DE HOME
getNext() {
  this.interval = setInterval (() => {
      if(this.indexActual == this.fotos.length - 1) {
        this.indexActual = 0;
      }
      else {
        this.indexActual++;
      }
  }, this.espera);
}

}
