import { Routes, RouterModule } from '@angular/router';

import { HomeComponent} from '../app/components/home/home.component';
import { EmpresaComponent} from '../app/components/empresa/empresa.component';
import { GranalladosComponent} from '../app/components/granallados/granallados.component';
import { PinturaComponent} from '../app/components/pintura/pintura.component';
import { CalidadComponent} from '../app/components/calidad/calidad.component';
import { ActualidadComponent } from '../app/components/actualidad/actualidad.component';

//import { ContactoComponent} from '../app/components/contacto/contacto.component';
import { DinamicaComponent} from '../app/components/dinamica/dinamica.component';


const app_routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'empresa', component: EmpresaComponent },
  { path: 'granallados', component: GranalladosComponent },
  { path: 'pintura', component: PinturaComponent },
  { path: 'calidad', component: CalidadComponent },
  { path: 'actualidad', component: ActualidadComponent },
  { path: 'actualidad/:id', component: ActualidadComponent },
  { path: 'aviso-legal', component: DinamicaComponent },
  { path: 'politica-de-privacidad', component: DinamicaComponent },
  { path: 'politica-de-cookies', component: DinamicaComponent },

  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];


export const app_routing = RouterModule.forRoot(app_routes);
